<template>
  <section>

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'browse-campaigns' }">
              Campaigns
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'view-campaign', params: { id: resourceId } }">
              Campaign Details
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Create Design
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <b-button
          v-if="campaignLoaded"
          variant="primary"
          :to="{ name: 'view-campaign', params: { id: resourceId } }"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="align-text-top"
          /> View Campaign
        </b-button>
      </b-col>
    </b-row>

    <!-- main content -->
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <!-- loading overlay -->
      <template #overlay>
        <div class="text-center">
          <h2>
            Processing design...
          </h2>
          <b-spinner
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="Large Spinner"
          />
        </div>
      </template>

      <b-card class="p-1">
        <b-card-title class="pl-1 border-left-primary border-left-3">
          Create {{ resourceName }}
        </b-card-title>

        <!-- 403 error message -->
        <b-row v-if="errorMessage">
          <b-col cols="12">
            <b-alert
              variant="danger"
              show
            >
              <div class="alert-body">
                {{ errorMessage }}
              </div>
            </b-alert>
          </b-col>
          <b-col cols="12">
            <b-button
              class="my-1"
              variant="danger"
              :to="{ name: 'browse-campaigns' }"
            >
              Return to Campaigns
            </b-button>
          </b-col>
        </b-row>

        <b-row v-if="campaignLoaded">
          <b-col cols="12">
            <b-nav
              id="design_nav"
              class="wrap-border"
              align="center"
            >
              <b-nav-item
                v-for="item in designLocations"
                :key="`design-location-${item.id}`"
                :active="currentLocation === item.id"
                @click="setQrLocation(item.id)"
              >
                {{ item.name }}
              </b-nav-item>
            </b-nav>
          </b-col>

          <b-col
            md="6"
            class="my-2 design_canvas"
          >
            <div
              id="design_preview"
              class="my-2"
            >
              <b-aspect
                aspect="1"
                class="my-1 design_container"
                :style="{ 'background-image': `url(${currentPreviewImage})` }"
              >
                <div class="canvas_overlay_half">
                  <!-- template overlay -->
                </div>
                <span>
                  <!-- qr codes -->
                </span>
              </b-aspect>
            </div><!-- design_preview -->
          </b-col>

          <b-col
            md="6"
            class="my-2"
          >
            <!-- form -->
            <validation-observer
              ref="resourceForm"
              #default="{invalid, dirty}"
            >
              <b-form @submit.prevent="submitDesignImage">
                <b-row>
                  <!-- design image -->
                  <b-col cols="12">
                    <h3>Design Image</h3>
                    <b-form-group
                      label="Image File"
                      label-for="design_image"
                      description="Image must be CMYK and in JPG format. Dimensions should be square (1:1) and 300 dpi for best results. Site preview is approximate and may not exactly match the final image."
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Design Image"
                        rules="required"
                        vid="design_image"
                      >
                        <b-form-file
                          id="design_image"
                          ref="designImageFileInput"
                          v-model="designImage"
                          accept="image/jpeg"
                          :state="errors.length > 0 ? false:null"
                          @input="pickImageFile"
                        />
                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- submit image button -->
                  <b-col
                    cols="12"
                    class="mb-2"
                  >
                    <b-button
                      type="submit"
                      variant="primary"
                      :disabled="invalid || !dirty"
                    >
                      Upload Image
                    </b-button>
                  </b-col>

                  <!-- qr codes -->
                  <b-col
                    cols="12"
                    class="mt-1 mb-2"
                  >
                    <h3>QR Codes</h3>

                    <b-button
                      v-if="qrCodesExist"
                      type="button"
                      variant="primary"
                      @click="openCodeCreateModal"
                    >
                      Add QR Code
                    </b-button>

                    <b-button
                      v-if="!qrCodesExist"
                      type="button"
                      variant="outline-primary"
                      class="ml-1"
                      @click="createQrCodes"
                    >
                      Create QR Codes
                    </b-button>

                    <!-- qr code placements -->
                    <div
                      v-if="qrCodesExist && selectedQrPlacements.length < 1"
                      class="mt-1 text-muted"
                    >
                      Click "Add QR Code" to place QR codes on your design.
                    </div>
                    <b-list-group class="mt-1">
                      <b-list-group-item
                        v-for="(item, index) in selectedQrPlacements"
                        :key="`code-placement-${index}`"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span>
                          {{ getQrPlacementName(item.qr_placement_id) }} - {{ getQrCodeName(item.qr_code_id) }}
                        </span>
                        <b-badge
                          variant="danger"
                          @click="deleteQrCodePlacement(index)"
                        >
                          Delete
                        </b-badge>
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>

                  <b-col
                    cols="12"
                    class="mb-3"
                  >
                    <h3>Printable Designs</h3>

                    <div
                      v-if="campaignData && !campaignData.design_images"
                      class="text-muted"
                    >
                      To view your printable designs, upload design images and create QR codes.
                    </div>

                    <b-button
                      v-if="campaignData.design_images_printable && campaignData.design_images_printable[0]"
                      v-b-modal.modal-view-printable
                      type="button"
                      variant="primary"
                      class="mr-1"
                    >
                      View Printable Designs
                    </b-button>

                    <b-button
                      v-if="campaignData && campaignData.design_images"
                      type="button"
                      variant="outline-primary"
                      @click="submitPrintableDesign"
                    >
                      Create Printable Design
                    </b-button>
                  </b-col>

                  <b-col
                    v-if="campaignData.design_images_printable && campaignData.design_images_printable"
                    cols="12"
                  >
                    <h3>Finalize</h3>

                    <b-button
                      v-if="campaignData.design_images_printable && campaignData.design_images_printable"
                      type="button"
                      variant="danger"
                      class="mr-1"
                      @click="finalizeDesign"
                    >
                      Finalize Design
                    </b-button>

                    <!-- <b-button
                      v-if="campaignData && campaignData.design_images[0]"
                      type="button"
                      variant="danger"
                      @click="unfinalizeDesign"
                    >
                      Cancel Finalized Design
                    </b-button> -->
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

    <!-- create qr code modal -->
    <b-modal
      id="modal-create-code"
      modal-class="modal-primary"
      size="md"
      centered
      hide-footer
      no-close-on-backdrop
      title="Create QR Code"
    >
      <validation-observer
        ref="qrCodeForm"
        #default="{invalid, dirty}"
      >
        <b-form @submit.prevent="createQrCodePlacement">
          <b-row>
            <!-- qr code size -->
            <b-col cols="12">
              <b-form-group
                label="QR Code Size"
                label-for="code-size"
              >
                <validation-provider
                  #default="{ errors }"
                  name="QR Code Size"
                  rules="required"
                  vid="code-size"
                >
                  <b-form-radio-group
                    id="code-size"
                    v-model="qrCodeForm.qr_code_id"
                    name="code-size"
                    :options="campaignData.qr_codes"
                    value-field="id"
                    text-field="name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- qr code size -->
            <b-col cols="12">
              <b-form-group
                label="QR Code Placement"
                label-for="code-placement"
              >
                <validation-provider
                  #default="{ errors }"
                  name="QR Code Placement"
                  rules="required"
                  vid="code-placement"
                >
                  <b-form-radio-group
                    id="code-placement"
                    v-model="qrCodeForm.qr_placement_id"
                    name="code-placement"
                    :options="qrPlacements"
                    value-field="id"
                    text-field="name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit -->
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                variant="outline-secondary"
                class="mr-1"
                @click="closeCodeCreateModal"
              >
                Cancel
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                :disabled="invalid || !dirty"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- view printable design modal -->
    <b-modal
      id="modal-view-printable"
      modal-class="modal-primary"
      size="xl"
      ok-only
      centered
      scrollable
      title="Preview Printable Designs"
    >
      <div
        v-if="campaignData"
        class="text-center preview-container"
      >
        <b-link
          v-if="campaignData.design_images_printable && campaignData.design_images_printable[0]"
          :href="campaignData.design_images_printable[0].url"
          target="_blank"
        >
          <b-img
            class="p-2 preview-image"
            :src="campaignData.design_images_printable[0].url"
          />
        </b-link>
        <b-link
          v-if="campaignData.design_images_printable && campaignData.design_images_printable[1]"
          :href="campaignData.design_images_printable[1].url"
          target="_blank"
        >
          <b-img
            class="p-2 preview-image"
            :src="campaignData.design_images_printable[1].url"
          />
        </b-link>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BCard, BOverlay, BSpinner,
  BNav, BNavItem, BAspect, BImg, BLink,
  BForm, BFormGroup, BFormFile, BFormRadioGroup,
  BButton, BCardTitle,
  BListGroup, BListGroupItem, BBadge, BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BOverlay,
    BSpinner,
    BNav,
    BNavItem,
    BAspect,
    BImg,
    BLink,
    BForm,
    BFormGroup,
    // BFormInput,
    BFormFile,
    BFormRadioGroup,
    BButton,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BBadge,
    BAlert,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      resourceName: 'Design',
      resourceNamePlural: 'Designs',

      // root user
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user
      userData: JSON.parse(localStorage.getItem('userData')),

      // form data
      designImage: null,
      selectedQrPlacements: [],
      qrCodeForm: {
        qr_code_id: null,
        qr_placement_id: null,
      },
      campaignLoaded: false,
      errorMessage: null,
      isLoading: false,

      // image preview
      currentLocation: 3, // current design location
      previewImage: null,

      // qr code placements
      currentQrPlacements: [],

      // validations
      required,
    }
  },
  computed: {
    resourceId() {
      return this.$route.params.id
    },
    campaignData() {
      return this.$store.getters['campaigns/getCampaign']
    },
    designLocations() {
      return this.$store.getters['campaignsDesign/getDesignLocations']
    },
    qrPlacements() {
      return this.$store.getters['campaignsDesign/getQrPlacements']
    },
    qrCodesExist() {
      // determine if qr codes exist for this campaign
      if (this.campaignData && this.campaignData.qr_codes) {
        if (this.campaignData.qr_codes.length > 0) {
          return true
        }
      }
      return false
    },
    currentLocationFace() {
      // determine if this is currently "inside" or "outside"
      if (this.campaignData) {
        const filteredData = this.designLocations.filter(item => item.id === this.currentLocation)
        return filteredData[0].name.replace(/ .*/, '')
      }
      return null
    },
    currentLocationData() {
      // retrieve image data for current location, if exists
      if (this.campaignData && this.campaignData.design_images) {
        const designImageData = this.campaignData.design_images
        const filteredData = designImageData.filter(item => item.location_id === this.currentLocation)
        return filteredData[0]
      }
      return null
    },
    currentPrintableDesignData() {
      // retrieve printable design data for current location, if exists
      if (this.campaignData && this.campaignData.design_images_printable) {
        const printableDesignData = this.campaignData.design_images_printable
        const filteredData = printableDesignData.filter(item => item.location === this.currentLocationFace)
        return filteredData[0]
      }
      return false
    },
    currentQrCodePlacementData() {
      // TODO: show saved qr placements
      return null
    },
    currentPreviewImage() {
      // return preview image (either existing in data or user uploaded image)
      let previewUrl = null
      if (this.previewImage) {
        previewUrl = this.previewImage
      } else if (this.currentLocationData) {
        previewUrl = this.currentLocationData.url
      }
      return previewUrl
    },
  },
  created() {
    if (this.userData.role.id === 1) {
      // set admin access if root user
      this.adminAccess = true
      this.adminSuffix = 'AsRoot'
    }

    // fetch campaign
    const campaignId = this.resourceId
    this.$store.dispatch(`campaigns/fetchCampaign${this.adminSuffix}`, campaignId)
      .then(() => { this.campaignLoaded = true })
      .catch(error => { this.errorMessage = error.error })

    // fetch design locations and qr placements
    this.$store.dispatch('campaignsDesign/fetchDesignLocations')
    this.$store.dispatch('campaignsDesign/fetchQrPlacements')
  },
  methods: {
    showErrorMessage(errorMessage) {
      this.$swal({
        title: 'Error',
        text: errorMessage,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    },
    createQrCodes(options) {
      const hideMessage = (options && options.hideMessage) ? options.hideMessage : false

      useJwt[`addCampaignQrCode${this.adminSuffix}`](
        this.resourceId,
      )
        .then(response => {
          console.log('createQrCodes', response.data) // DELETE LATER
          if (response.data.data) {
            const resourceData = response.data.data
            if (!hideMessage) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${resourceData.name} Saved`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'QR codes successfully created.',
                },
              })
            }
          }
          this.refreshCampaignData()
        })
        .catch(error => {
          this.showErrorMessage(error.response.data.error)
        })
    },
    submitDesignImage() {
      this.$refs.resourceForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.resourceForm.reset()

          // delete previous image if exists
          if (this.currentLocationData) {
            this.deleteDesignImage({ hideMessage: true })
          }

          useJwt[`addDesignImage${this.adminSuffix}`](
            this.designImage,
            this.resourceId,
            this.currentLocation,
          )
            .then(response => {
              const resourceData = response.data.data

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${resourceData.name} Saved`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Design image created successfully.',
                },
              })
              this.resetDesignForm()
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.resourceForm.setErrors(error.response.data.error)
                this.showErrorMessage(error.response.data.error)
              } else if (error.response.status === 403) {
                this.showErrorMessage(error.response.data.error)
              }
            })
        }
      })
    },
    deleteDesignImage(options) {
      const hideMessage = (options && options.hideMessage) ? options.hideMessage : false

      useJwt[`deleteDesignImage${this.adminSuffix}`](
        this.resourceId,
        this.currentLocation,
      )
        .then(response => {
          const resourceData = response.data.data
          if (!hideMessage) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${resourceData.name} Saved`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Design image deleted successfully.',
              },
            })
            this.resetDesignForm()
          }
        })
        .catch(error => {
          this.showErrorMessage(error.response.data.error)
        })
    },
    resetDesignForm() {
      this.previewImage = null // reset image preview
      this.designImage = null // reset image form
      this.refreshCampaignData()
    },
    refreshCampaignData() {
      this.$store.dispatch(`campaigns/fetchCampaign${this.adminSuffix}`, this.resourceId)
    },
    selectImage() {
      this.$refs.designImageFileInput.click()
    },
    pickImageFile() {
      const input = this.$refs.designImageFileInput
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit('input', file[0])
      }
    },
    setQrLocation(locationId) {
      if (locationId === this.currentLocation) {
        // matches current location, do nothing
      } else {
        this.previewImage = null // reset image preview
        this.designImage = null // reset image form
        this.currentLocation = locationId
      }
    },
    createQrCodePlacement() {
      const currentPlacements = [...this.selectedQrPlacements]
      currentPlacements.push(this.qrCodeForm)
      this.selectedQrPlacements = currentPlacements
      this.closeCodeCreateModal()
    },
    deleteQrCodePlacement(index) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const currentPlacements = [...this.selectedQrPlacements]
          currentPlacements.splice(index, 1)
          this.selectedQrPlacements = currentPlacements

          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    getQrCodeName(codeId) {
      if (this.campaignData) {
        const qrPlacementData = this.campaignData.qr_codes
        const filteredData = qrPlacementData.filter(item => item.id === codeId)
        return filteredData[0].name
      }
      return null
    },
    getQrPlacementName(placementId) {
      const qrPlacementData = this.qrPlacements
      const filteredData = qrPlacementData.filter(item => item.id === placementId)
      return filteredData[0].name
    },
    resetCodeCreateForm() {
      this.qrCodeForm = {
        qr_code_id: null,
        qr_placement_id: null,
      }
    },
    openCodeCreateModal() {
      this.resetCodeCreateForm()
      this.$bvModal.show('modal-create-code')
    },
    closeCodeCreateModal() {
      this.$bvModal.hide('modal-create-code')
      this.resetCodeCreateForm()
    },
    submitPrintableDesign() {
      let confirmMessage = `This will create a printable "${this.currentLocationFace}" design. It may take a few minutes.`
      if (this.currentPrintableDesignData) {
        confirmMessage = `This will overwrite your previous "${this.currentLocationFace}" design. It may take a few minutes.`
      }
      this.$swal({
        title: 'Are you sure?',
        text: confirmMessage,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, create it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true

          useJwt[`createPrintableDesignImage${this.adminSuffix}`](
            this.resourceId,
            this.currentLocationFace,
            this.selectedQrPlacements,
          )
            .then(() => {
              this.refreshCampaignData()
              this.isLoading = false

              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: `Your printable design for "${this.currentLocationFace}" has been created.`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              this.isLoading = false
              this.showErrorMessage(error.response.data.error)
            })
        }
      })
    },
    finalizeDesign() {
      const confirmMessage = `This will finalize your "${this.currentLocationFace}" design for printing.`
      /* if (this.campaignData && this.campaignData.design_images_printable.length > 0) {
        confirmMessage = `This will overwrite your previous "${this.currentLocationFace}" design for printing.`
      } */
      this.$swal({
        title: 'Are you sure?',
        text: confirmMessage,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, finalize it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true

          useJwt[`createFinalDesignImage${this.adminSuffix}`](
            this.resourceId,
            this.currentLocationFace,
            this.selectedQrPlacements,
          )
            .then(() => {
              this.refreshCampaignData()
              this.isLoading = false

              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: `Your printable design for "${this.currentLocationFace}" has been finalized.`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              this.isLoading = false
              this.showErrorMessage(error.response.data.error)
            })
        }
      })
    },
    unfinalizeDesign() {
      const confirmMessage = `This will cancel your finalized "${this.currentLocationFace}" design for printing.`
      this.$swal({
        title: 'Are you sure?',
        text: confirmMessage,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true

          useJwt[`deleteFinalDesignImage${this.adminSuffix}`](
            this.resourceId,
            this.currentLocationFace,
          )
            .then(() => {
              this.refreshCampaignData()
              this.isLoading = false

              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: `Your finalized printable design for "${this.currentLocationFace}" has been canceled.`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              this.isLoading = false
              this.showErrorMessage(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
#design_nav .nav-link {
  text-transform: capitalize;
}
#design_nav .nav-link.active {
  font-weight: 600;
}
.design_canvas {
  background-color: #FFF;
}
.design_container {
  background-size: cover;
  background-position: center center;
}
.canvas_overlay_half {
  height: 100%;
  width: 100%;
  background: url('./base_template_half.png') no-repeat;
  background-size: cover;
  background-position: center center;
}
.preview-container .preview-image {
  max-height: 80vh;
}
</style>
